<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {dateUtil} from "@/helpers/date-util";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";
import {swalHelper} from "@/helpers/swal-helper";
import {required} from "vuelidate/lib/validators";

/**
 * Help Videos Component
 */
export default {

  components: {
    Layout,
    PageHeader
  },

  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  data() {
    return {
      submitted: false,
      languageId: "",

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        isBusy: false,

        elements: []
      },

      modals: {
        helpVideo: {
          visible: false,
          form: {}
        }
      }

    };
  },

  validations: {
    modals: {
      helpVideo: {
        form: {
          path: {required},
          videoType: { required },
          videoId: {required}
        }
      }
    }
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: "Filmy pomocnicze",
          active: true
        }
      ]
    },

    getFields() {
      return [
        { key: "path", label: "Ścieżka"},
        { key: "videoId", label: "ID Filmu"},
        { key: "deleted", label: "Usunięte" },
        { key: "createdAt", label: "Data utworzenia", formatter: value => dateUtil.asDateTime(value) },
        { key: "updatedAt", label: "Data aktualizacji", formatter: value => dateUtil.asDateTime(value) },
        { key: "deletedAt", label: "Data usunięcia", formatter: value => dateUtil.asDateTime(value) },
        { key: "action", label: this.$t('table.actions') }
      ]
    },

    async loadHelpVideos() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const { data } = await axios.get(`/help-video/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": this.table.perPage,
          "languageId": this.languageId
        }
      });

      const { helpVideos, count } = data

      this.table.elements = helpVideos
      this.table.totalRows = count
      this.table.rows = count

      if (this.mounted) {
        this.$refs.table.refresh()
      }

      return this.table.elements;
    },

    openHelpVideoModal(helpVideo) {
      if (helpVideo) {
        this.modals.helpVideo.form = Object.assign({}, helpVideo)
      }

      this.modals.helpVideo.visible = true
    },

    hideHelpVideoModal() {
      this.modals.helpVideo.visible = false
      this.modals.helpVideo.form = {}
    },

    createOrEditHelpVideo() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.modals.helpVideo.form.$touch();
      if (this.$v.modals.helpVideo.form.$invalid) {
        return;
      }

      const json = JSON.stringify({
        languageId: this.languageId,
        ...this.modals.helpVideo.form
      });

      axios.put(`/help-video`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.hideHelpVideoModal()
        Swal.fire("Sukces!", this.modals.helpVideo.form.id ? "Zaktualizowano film pomocniczy" : "Dodano nowy film pomocniczy", "success")
            .then(() => this.$refs.table.refresh())
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    deleteHelpVideo(helpVideo) {
      swalHelper.yesOrNoCustomizable(() => {
        axios.delete(`/help-video/${helpVideo.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        }).then(() => {
          Swal.fire("Sukces!", "Film pomocniczy został usunięty!", "success")
              .then(() => this.$refs.table.refresh());
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      }, "warning", "Czy na pewno?", "Film pomocniczy zostanie usunięty", "Usuń", "Anuluj", "btn btn-danger", "btn btn-secondary")
    },

    getVideoTypeLabel(videoType) {
      if (videoType === "VIMEO") {
        return "Vimeo"
      } else if (videoType === "YOUTUBE") {
        return "YouTube"
      }

      return videoType
    }

  },

  async created() {
    const id = this.$route.params.id;
    if (!id) {
      await this.$router.push('/dashboard/home').then(() => {
        Swal.fire("", "Nie znaleziono takiego języka!", "error");
      }).catch((error) => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.log(error)
        }
      })
      return;
    }

    this.languageId = id
  }

};
</script>

<template>
  <Layout>
    <PageHeader title="Filmy pomocnicze" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="button-items">
              <b-button variant="success" @click="openHelpVideoModal(null)">Dodaj nowy film pomocniczy</b-button>
            </div>

            <div class="py-4">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.show') }}&nbsp;
                      <b-form-select v-model="table.perPage" size="sm" :options="table.pageOptions" />&nbsp;{{ $t('table.entries') }}
                    </label>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.search') }}
                      <b-form-input
                          v-model="table.filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                          @keyup.enter="setToFirstPageAndRefresh" @input="setToFirstPageAndRefresh"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-right">
                    <label class="d-inline-flex align-items-center mx-2">
                      <b-form-input v-model="table.inputPage" class="form-control form-control-sm ml-2" />
                    </label>

                    <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, table.inputPage)">{{ $t('table.go-to-page') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="row py-3">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage" />
                    </ul>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <b-table

                    ref="table"
                    :items="loadHelpVideos"
                    :fields="getFields()"
                    responsive="sm"
                    :per-page="table.perPage"
                    :current-page="table.currentPage"
                    :sort-by.sync="table.sortBy"
                    :sort-desc.sync="table.sortDesc"
                    :filter-included-fields="table.filterOn"
                    @filtered="elements => paginationHelper.onFiltered(table, elements)"
                    :empty-text="$t('message.no-elements')"
                    :empty-filtered-text="$t('message.no-records')" :show-empty="true"
                    :busy.sync="table.isBusy">
                  <div slot="table-busy" class="text-center">
                    <h5>{{ $t('message.loading') }}</h5>
                    <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
                  </div>

                  <template v-slot:cell(deleted)="{ item }">
                    <span :class="item.deleted ? 'badge badge-soft-success' : 'badge badge-soft-danger'" class="font-size-11">{{ $t(item.deleted ? 'message.yes' : 'message.no') }}</span>
                  </template>

                  <template v-slot:cell(action)="{ item }">
                    <div class="button-items">
                      <b-button variant="primary" class="btn-sm" @click="openHelpVideoModal(item)">Edytuj</b-button>
                      <b-button variant="danger" class="btn-sm" @click="deleteHelpVideo(item)">Usuń</b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-left">
                    <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(table), 'elements': paginationHelper.getElementsCount(table), 'all': table.totalRows }) }}</p>
                  </div>

                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="modals.helpVideo.visible"
        :title="modals.helpVideo.form.id ? 'Edytowanie filmu pomocniczego' : 'Dodawanie nowego filmu pomocniczego'"
        title-class="font-18"
        hide-footer
        @hide="hideHelpVideoModal"
        @esc="hideHelpVideoModal">
      <div class="form-group">
        <label>Ścieżka</label>
        <input v-model="modals.helpVideo.form.path" type="text" class="form-control" :class="{ 'is-invalid': $v.modals.helpVideo.form.path.$error }" />
        <div v-if="submitted && !$v.modals.helpVideo.form.path.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="form-group">
        <label>Typ Filmu</label>
        <vue-multiselect
            v-model="modals.helpVideo.form.videoType"
            :options="['VIMEO', 'YOUTUBE']"
            :custom-label="getVideoTypeLabel"
            placeholder="Wybierz typ"
            :class="{ 'is-invalid': $v.modals.helpVideo.form.videoType.$error }"
            :show-labels="false"
        />
        <div v-if="submitted && !$v.modals.helpVideo.form.videoType.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="form-group">
        <label>ID Filmu</label>
        <input v-model="modals.helpVideo.form.videoId" type="text" class="form-control" :class="{ 'is-invalid': $v.modals.helpVideo.form.videoId.$error }" />
        <div v-if="submitted && !$v.modals.helpVideo.form.videoId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="text-right">
        <b-button @click="createOrEditHelpVideo" variant="success">{{ modals.helpVideo.form.id ? 'Edytuj' : 'Utwórz' }}</b-button>
        <b-button class="ml-1" variant="danger" @click="hideHelpVideoModal">{{  $t('message.cancel') }}</b-button>
      </div>
    </b-modal>

  </Layout>
</template>